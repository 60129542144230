import React, {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {ThemeContext} from '../../context'

const LayoutOnChain = () => {
    const {changeDesignMode, changeShowcaseType} = useContext(ThemeContext)

    useEffect(() => {
        changeShowcaseType('onchain')
        changeDesignMode('onchain-mode')
        return () => {
            changeShowcaseType(null)
            changeDesignMode(null)
        }
    }, [])

    return <Outlet/>
}

export default LayoutOnChain
